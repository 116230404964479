import { createWebHistory, createRouter } from 'vue-router'
import { retriableLazyImport } from '@gcx-si/vue-components/retriableLazyImport'
import LoadingAnimation from '@gcx-si/vue-components/LoadingAnimation'

import type { RouteRecordRaw } from 'vue-router'

import { useAuthenticatedGuard, useDefaultRouteGuard } from '@/shared/auth/useAuthenticationGuards.ts'
import { RouteNames } from '@/setup/constants.ts'

const routeModules = import.meta.glob('../apps/**/routes.ts', { eager: true }) as Record<string, RouteRecordRaw[]>
const importedRoutes = Object.values(routeModules).map(module => Object.values(module))
const flattenedRoutes = importedRoutes.flat(2)

const publicRouteModules = import.meta.glob('../apps/**/public-routes.ts', { eager: true }) as Record<string, RouteRecordRaw[]>
const importedPublicRoutes = Object.values(publicRouteModules).map(module => Object.values(module))
const flattenedPublicRoutes = importedPublicRoutes.flat(2)

declare module 'vue-router' {
  interface RouteMeta {
    navigation?: {
      labelKey: string
      targetName: string
    }
  }
}

export const router = createRouter({
  history: createWebHistory(),

  scrollBehavior(_to, _from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { top: 0 }
    }
  },

  routes: [
    ...flattenedPublicRoutes,
    {
      path: '/operator/:gatewayOperatorId',
      component: retriableLazyImport(() => import('@/shared/components/UserAppLayout.vue')),
      children: flattenedRoutes,
      beforeEnter: useAuthenticatedGuard(),
    },
    {
      path: '/:pathMatch(.*)*',
      name: RouteNames.DEFAULT_ROUTE,
      beforeEnter: useDefaultRouteGuard(),
      component: LoadingAnimation,
    },
  ],
})
